import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";

export const OriginsContainer = styled.div`
  background: linear-gradient(
    180deg,
    rgba(13, 0, 50, 0) 0%,
    #0d0032 0%,
    #0d0032 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 500px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 725px) {
    height: 600px;
  }

  @media screen and (max-width: 530px) {
    height: 650px;
  }

  @media screen and (max-width: 495px) {
    height: 600px;
  }

  :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

export const OriginsContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OriginsH1 = styled.h1`
  color: #fff;
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  text-align: center;
  margin-bottom: 15px;

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }

  @media screen and (max-width: 480px) {
    font-size: 36px;
    line-height: 3rem;
  }
`;

export const OriginsH2 = styled.h1`
  color: #fff;
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #93d3ff;
  margin-bottom: 15px;

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }

  @media screen and (max-width: 480px) {
    font-size: 20px;
    line-height: 3rem;
  }
`;

export const OriginsP = styled.p`
  margin-top: 24px;
  color: #fff;
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 17.0013px;
  text-align: center;
  margin-bottom: 20px;
  max-width: 800px;
  text-shadow: 0px 3.40027px 41.6533px rgba(0, 0, 0, 0.23);

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const OriginsBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;
