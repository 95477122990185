import styled from "styled-components";
import { Link } from "react-router-dom";
// import  {Link as LinkR} from 'react-router-dom'

export const FooterContainer = styled.footer`
  background: linear-gradient(
    180deg,
    rgba(28, 18, 52, 0) -75px,
    #1c1234 0px,
    #1c1234 0%
  );
`;

export const FooterWrap = styled.div`
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
`;
export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialLogo = styled(Link)`
  color: #fff;
  margin-bottom: 16px;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: bold;
`;
export const ServicesIcon = styled.img`
  /* height:90px;
    width:250px; */
  margin-bottom: 2rem;
`;

export const WebsiteRights = styled.small`
  color: #fff;
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 20px;
`;
export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90px;
`;

export const SocialIconsFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width:240px; */

  @media screen and (max-width: 625px) {
    margin-right: 20px;
  }

  @media screen and (max-width: 545px) {
    margin-right: 10px;
  }
`;

export const SocialIcon = styled.img`
  /* height:32px;
    width:30px; */
  cursor: pointer;
  margin-bottom: 2rem;
`;
export const SocialIcon1 = styled.img`
  /* height:50px;
    width:50px; */
  cursor: pointer;
  /* margin-bottom:10px; */
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;

  @media screen and (max-width: 495px) {
    font-size: 22px;
  }
`;

export const FooterContent = styled.div`
  z-index: 3;
  max-width: 1300px;
  padding: 8px 2px;
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const FooterP = styled.p`
  margin-top: 24px;
  color: #ffffff;
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  max-width: 1300px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 625px) {
    font-size: 20px;
  }

  @media screen and (max-width: 545px) {
    font-size: 18px;
  }

  @media screen and (max-width: 390px) {
    font-size: 15px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

export const HorizontalLine = styled.hr`
  width: 100%;
  height: 1px;
  border: 0 none;
  margin-right: auto;
  margin-left: auto;
  color: #93d3ff;
  margin-top: 20px;
  margin-bottom: 0.5rem;
  background: #93d3ff;
`;

export const Footerdata = styled.div`
  z-index: 3;
  width: 100%;
  /* padding:8px 24px; */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;
export const Anchortag = styled.a`
  color: #ffcf30;
`;

export const LogoFooter = styled.div`
  display: flex;
  width: 360px;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    width: 580px;
  }

  @media screen and (max-width: 625px) {
    width: auto;
  }
`;
