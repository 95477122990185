import React from "react";
import Pic1 from "../pics/Logo Regular.svg";
import { Button } from "../button/ButtonElements";
import {
  TeamContainer,
  TeamCard,
  TeamCard1,
  TeamH1,
  TeamH2,
  TeamIcon,
  TeamWrapper,
  TeamBtnWrapper,
  TeamLogo,
  TeamH3,
  TeamCard3,
} from "./Teamelements";

const Team = () => {
  return (
    <TeamContainer id="team">
      <TeamWrapper>
        <TeamCard>
          <TeamH3>ABOUT US</TeamH3>
          <TeamH1>
            <a
              style={{ color: "white" }}
              target="_blank"
              href="https://tokenmetrics.com/team.html?&&hsLang=en "
            >
              MEET OUR TEAM
            </a>
          </TeamH1>
          <TeamBtnWrapper>
            {/* <Button to="signup" primary="true" dark="true">
              VISIT THE PAGE
            </Button> */}
          </TeamBtnWrapper>
        </TeamCard>
        <TeamCard1>
          <TeamCard3>
            <a href="https://tokenmetrics.com/">
              <TeamIcon src={Pic1}></TeamIcon>
            </a>
          </TeamCard3>
          <TeamCard3>
            <TeamH2>
              AI-driven crypto research platform which interprets data into
              actionable investment insights to help you beat the market. Token
              Metrics is featured in Business Insider, Forbes, CNBC, and CNN.
            </TeamH2>
          </TeamCard3>
        </TeamCard1>
      </TeamWrapper>
    </TeamContainer>
  );
};

export default Team;
