import styled from "styled-components";
import { Link } from "react-scroll";

export const Button = styled(Link)`
  background: #93d3ff;
  white-space: nowwrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#010606" : "#fff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: all 0, 2s ease-in-out;

  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  /* &:hover{
        transition: all 0,2s ease-in-out ;
        background: ${({ primary }) => (primary ? "#fff" : "##93D3FF")};
    } */
`;

export const ButtonConnected = styled(Link)`
  background: #93d3ff;
  white-space: nowwrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#010606" : "#fff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: all 0, 2s ease-in-out;

  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  /* &:hover{
        transition: all 0,2s ease-in-out ;
        background: ${({ primary }) => (primary ? "#fff" : "##93D3FF")};
    } */
`;
