import styled from "styled-components";
import astroimg from "../pics/botastro1.png";

export const MintMainContainer = styled.div`
  /* background: #0c0c0c; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 800px;
  position: relative;
  z-index: 1;

  @media screen and (nax-width: 500px) {
    height: 600px;
  }

  :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: radial-gradient(
      circle,
      rgba(61, 12, 223, 0.5242471988795518) 50%,
      rgba(32, 14, 235, 0) 98%
    );
    z-index: 2;
  }
`;

export const MintContainer = styled.div`
  /* background: #0c0c0c; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 850px;
  position: relative;
  z-index: 1;

  :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: radial-gradient(
      circle,
      rgba(61, 12, 223, 0.5242471988795518) 50%,
      rgba(32, 14, 235, 0) 98%
    );
    z-index: 2;
  }

  @media screen and (max-width: 1000px) {
    height: 1300px;
  }

  @media screen and (max-width: 540px) {
    height: 1450px;
  }

  @media screen and (max-width: 525px) {
    height: 1500px;
  }

  @media screen and (max-width: 480px) {
    height: 1400px;
  } ;
`;

export const MintBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  /* background: #232a34; */
`;

export const MintMainHeroContent = styled.div`
  z-index: 3;
  max-width: 800px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const MintTimerContanier = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 800px;
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media screen and (max-width: 1100px) {
    width: 700px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: 910px) {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 0.12rem;
    margin-bottom: 0.1rem;
  }
`;

export const MintTimerBlock = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid rgba(147, 211, 255, 1);
  background: rgba(217, 217, 217, 0.1);
  width: 150px;
  justify-content: center;
  align-items: center;
  height: 150px;

  @media screen and (max-width: 1100px) {
    width: 130px;
    height: 130px;
  }

  @media screen and (max-width: 910px) {
    width: 115px;
    height: 115px;
  }

  @media screen and (max-width: 700px) {
    width: 100px;
    height: 100px;
  }

  @media screen and (max-width: 575px) {
    width: 90px;
    height: 90px;
  }

  > h3 {
    font-family: "Chakra Petch";
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    margin: 0;
    display: flex;
    align-items: center;
    text-align: center;
    color: #fff;

    @media screen and (max-width: 1100px) {
      font-size: 50px;
    }

    @media screen and (max-width: 910px) {
      font-size: 40px;
    }

    @media screen and (max-width: 700px) {
      font-size: 38px;
    }

    @media screen and (max-width: 575px) {
      font-size: 35px;
    }
  }

  > p {
    font-family: "Chakra Petch";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 0;
    margin: 0;
    display: flex;
    align-items: center;
    text-align: center;
    color: #93d3ff;

    @media screen and (max-width: 1100px) {
      font-size: 22px;
    }

    @media screen and (max-width: 910px) {
      font-size: 20px;
    }

    @media screen and (max-width: 700px) {
      font-size: 18px;
    }
    @media screen and (max-width: 575px) {
      font-size: 16px;
    }
  }
`;

export const MintTimerDots = styled.p`
  font-family: "Chakra Petch";
  font-style: normal;
  font-size: 60px;
  margin: 0 20px;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  text-align: center;
  color: #fff;

  @media screen and (max-width: 1100px) {
    font-size: 50px;
  }

  @media screen and (max-width: 768px) {
    font-size: 40px;
    margin: 0 10px;
  }

  @media screen and (max-width: 575px) {
    margin: 0 5px;
  }

  @media screen and (max-width: 440px) {
    margin: 0 3px;
  }
`;

export const MintHeroContent = styled.div`
  z-index: 3;
  max-width: 1350px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const MintHeroContent1 = styled.div`
  z-index: 3;
  max-width: 1250px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MintHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 720px;

  @media screen and (max-width: 768px) {
    max-width: 620px;
  }
`;

export const MintH1 = styled.h1`
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 700;
  line-height: 95px;
  font-size: 40px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  text-align: center;
  color: #93d3ff;

  @media screen and (max-width: 1150px) {
    line-height: 65px;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 2;
  }

  @media (max-width: 540px) {
    line-height: 1.3;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;
export const MintMainP = styled.p`
  max-width: 1000px;
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  color: #ffffff;

  @media screen and (max-width: 850px) {
    font-size: 22px;
    line-spacing: 100%;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }

  @media screen and (max-width: 700px) {
    font-size: 16px;
  }
`;

export const MintP = styled.p`
  max-width: 1000px;
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-spacing: 170%;
  align-items: center;
  text-align: center;

  color: #ffffff;

  @media screen and (max-width: 850px) {
    font-size: 22px;
    display: flex;
    flex-wrap: wrap;
    line-height: 1.6;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;
export const MintBtnWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1100px) {
    margin-top: 0.5rem;
  }

  @media screen and (max-width: 910px) {
    margin-top: 0.2rem;
  }
`;

export const MintBtnWrapper1 = styled.div`
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MintBtnWrapper2 = styled.div`
  margin: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MintBottom = styled.div`
  max-width: 1250px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: transparent;
  align-items: center;

  @media screen and (max-width: 1000px) {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    width: auto;
  } ;
`;

export const MintContent = styled.div`
  width: 800px;
  display: flex;
  margin: 0 50px;
  flex-direction: column;
  border: 3px solid #93d3ff;
  background: rgba(217, 217, 217, 0.1);
  align-items: center;

  @media screen and (max-width: 1440px) {
    width: 400px;
    height: 400px;
  }

  @media screen and (max-width: 1150px) {
    margin: 0 40px 0 0;
    width: 350px;
  }

  @media (max-width: 1000px) {
    margin: 0;
    width: 400px;
  }

  @media screen and (max-width: 700px) {
    width: 400px;
  }

  @media screen and (max-width: 540px) {
    width: 300px;
  } ;
`;

export const MintH2 = styled.h1`
  line-height: 95px;
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;

  margin-bottom: 0;
  display: flex;
  align-items: center;
  text-align: center;
  color: #93d3ff;

  @media screen and (max-width: 1100px) {
    font-size: 54px;
  }

  @media screen and (max-width: 768px) {
    font-size: 42px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const MintContent1 = styled.div`
  width: 1000px;
  height: 450px;
  display: flex;
  margin: 0 50px;
  flex-direction: column;

  background: transparent;
  align-items: center;

  @media screen and (max-width: 1440px) {
    width: 500px;
  }

  @media screen and (max-width: 1150px) {
    margin: 0 0 0 40px;
  }

  @media (max-width: 1000px) {
    margin-top: 2rem;
    margin-left: 0;
    width: 500px;
  }

  @media screen and (max-width: 700px) {
    width: 400px;
  }

  @media screen and (max-width: 540px) {
    width: 300px;
    height: auto;
  } ;
`;

export const Wrapper = styled.div`
  height: 60px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: #fff;
`;

export const Minus = styled.span`
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: #93d3ff;
  }
`;

export const Plus = styled.span`
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: #93d3ff;
  }
`;

export const Number = styled.span`
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid #93d3ff;
`;

export const MintP1 = styled.p`
  margin-top: 0rem;
  max-width: 900px;
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  /* line-height: 169%; */
  align-items: center;
  text-align: center;

  color: #ffffff;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const MintP2 = styled.p`
  margin-bottom: 0;
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #93d3ff;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const MintP3 = styled.p`
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 0;
  line-height: 169%;
  /* identical to box height, or 27px */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const MintP4 = styled.p`
  width: 630px;
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  /* identical to box height, or 27px */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;

  @media screen and (max-width: 1440px) {
    width: auto;
    line-height: 1.6;
  }

  @media screen and (max-width: 850px) {
    font-size: 22px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const SelectButton = styled.select`
  background: #d2edff;
  border-radius: 60px;
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  height:51px;
  max-width: 240px;
  line-height: 22px;=
  display:flex;
  justify-content:center;
  align-items:center;
  padding-right:30px;
  margin:0;
  padding-left:20px;
  
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
`;

export const Anchor = styled.a`
  box-sizing: border-box;
  width: 271px;
  height: 51px;
  background: #93d3ff;
  border: 3px solid #93d3ff;
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: black;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;

  @media screen and (max-width: 768px) {
    width: auto;
    height: auto;
    line-height: 0x;
    padding: 10px 20px;
  }
`;
export const Imageastro = styled.div`
  height: 284px;
  width: 284px;
  background-repeat: no-repeat;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 30px;
  margin-bottom: 35px;
  // border: 3px solid #93d3ff;
`;
