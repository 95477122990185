import React from "react";
import {
  OriginsContainer,
  OriginsBtnWrapper,
  OriginsContent,
  OriginsH1,
  OriginsP,
  OriginsH2,
} from "./originselement";
import { Anchor } from "./origins1elements";
import { Button } from "../button/ButtonElements";

const Origins = () => {
  return (
    <OriginsContainer id="origins">
      <OriginsContent>
        <OriginsH2>ORIGINS</OriginsH2>

        <OriginsH1>ASTROBOT ORIGINS</OriginsH1>
        <OriginsP>
          The future of an empowered universe lies in the hands of the mighty
          Astrobots. Will the Astrobots gather enough materials to provide
          energy for the AI engine to take out Unasento? Or will Unasento
          capture and demolish them all to achieve his mission of galactic
          destruction?
        </OriginsP>
        <Anchor href="/origins" primary="true" dark="true">
          Read More
        </Anchor>
        <OriginsBtnWrapper></OriginsBtnWrapper>
      </OriginsContent>
    </OriginsContainer>
  );
};

export default Origins;
