import React from "react";
import {
  HorizontalLine,
  LogoIcon,
  SocialIcons,
  SocialIconLink,
  SocialIcon,
  SidebarContainer,
  Icon,
  CloseIcon,
  SideBtnWrap,
  SidebarLink,
  SidebarRoute,
  SidebarWrapper,
  SidebarMenu,
} from "./sidebarelements";
import Discort from "../pics/discort.svg";
import twitter from "../pics/twitter.svg";
import Logo from "../pics/Group 95.svg";
import opensea from "../pics/opensea-seeklogo.com 1.svg";

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarRoute to="/" onClick={toggle}>
            <LogoIcon src={Logo} />
          </SidebarRoute>
          <HorizontalLine></HorizontalLine>
          {/* <SidebarLink to="/#moonmap" onClick={toggle}>
            MOONMAP
          </SidebarLink> */}
          <SidebarLink to="/#team" onClick={toggle}>
            TEAM
          </SidebarLink>
          <SidebarRoute to="/origins" onClick={toggle}>
            ORIGINS
          </SidebarRoute>
          <SidebarRoute to="/mint" onClick={toggle}>
            MINT
          </SidebarRoute>
          <SidebarLink to="/#faq" onClick={toggle}>
            FAQ
          </SidebarLink>
        </SidebarMenu>
        <HorizontalLine></HorizontalLine>
        <SideBtnWrap>
          <SocialIcons>
            {/* <SocialIconLink href='/' target="_blank" aria-label="Facebook"> <SocialIcon src={opensea}></SocialIcon></SocialIconLink> */}
            <SocialIconLink
              href="https://twitter.com/AstrobotSociety"
              target="_blank"
              aria-label="Facebook"
            >
              {" "}
              <SocialIcon src={twitter}></SocialIcon>
            </SocialIconLink>
            <SocialIconLink
              href="https://discord.com/invite/tokenmetricsofficial"
              target="_blank"
              aria-label="Facebook"
            >
              {" "}
              <SocialIcon src={Discort}></SocialIcon>
            </SocialIconLink>
          </SocialIcons>
        </SideBtnWrap>
        <SideBtnWrap>
          {/* <SidebarRoute to="/signin">CONNECT</SidebarRoute> */}
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
