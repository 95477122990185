import React, {useState, useEffect} from "react";
import {notification, Popover, Progress, Spin, Modal} from "antd";
import Helmet from "react-helmet";
import {ethers, utils} from 'ethers';
import {MerkleTree} from 'merkletreejs';
import keccak256 from 'keccak256';
import {Buffer} from "buffer";

// NFT related imports
import AstrobotNFT from '../artifacts/contracts/nft.sol/Astrobot.json';
import AstrobotSaleNFT from '../artifacts/contracts/sale.sol/Sale.json';
import NFTConfig from '../artifacts/config.json';

import Video from "../videos/nebula 1.mp4";
import {Button} from "../button/ButtonElements";
import {
  Anchor,
  Imageastro,
  MintBg,
  MintBottom,
  MintBtnWrapper,
  MintBtnWrapper1,
  MintBtnWrapper2,
  MintContainer,
  MintContent,
  MintContent1,
  MintH1,
  MintH2,
  MintHeader,
  MintHeroContent, MintMainContainer, MintMainHeroContent, MintMainP,
  MintP,
  MintP1,
  MintP2,
  MintP3,
  MintP4, MintTimerBlock, MintTimerContanier, MintTimerDots,
  Minus,
  Number,
  Plus,
  SelectButton,
  VideoBg,
  Wrapper,
} from "./mintelement";
import Sidebar from "../sidebar/sidebar";
import Footer from "../Footer/Footer";
import Header from "../Head/Header";
import FollowingScroll from "../scroll-to-top/followingScroll";
import img from "../pics/info icon.png";
import astroimg from "../pics/botastro1.png";
import astroGif from "../pics/Mint_Page_Gif.gif";

window.Buffer = window.Buffer || Buffer;

const Mint = () => {
  const link = "https://www.premint.xyz/astrobotsociety";

  const [isOpen, setIsOpen] = useState(false);
  const [timerDays, setTimerDays] = useState(0);
  const [timerHours, setTimerHours] = useState(0);
  const [timerMinutes, setTimerMinutes] = useState(0);
  const [timerSeconds, setTimerSeconds] = useState(0);

  const [num, setNum] = useState(1);
  const [percentage, setPercentage] = useState(0);
  const [ phase, setPhase ] = useState(0);
  const [ balance, setBalance ] = useState(0);
  const [ address, setAddress ] = useState();

  // Loading
  const [ connectingWallet, setConnectingWallet ] = useState(false);
  const [ minting, setMinting ] = useState(false);

  const [ numAlreadyMinted, setNumAlreadyMinted ] = useState(0);
  const [ totalMinted, setTotalMinted ] = useState(0);
  const [ numAlreadyMintedInAllPhases, setNumAlreadyMintedInAllPhases ] = useState(0);
  const [ totalRemaining, setTotalRemaining ] = useState(0);
  const [ totalNFTTokens, setTotalNFTTokens ] = useState(10000);
  const [ mintEnabled, setMintEnabled ] = useState(false);

  const [ saleContract, setSaleContract ] = useState();
  const [ mainContract, setMainContract ] = useState();

  const [ addressOnWhiteList, setAddressOnWhiteList ] = useState(false);
  const [ addressOnWaitList, setAddressOnWaitList ] = useState(false);

  const [ numberOfTokenCanBeMinted, setNumberOfTokenCanBeMinted ] = useState(3);
  const [ price, setPrice ] = useState(0.35);

  // design code
  let interval;

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const provider = new ethers.providers.JsonRpcProvider("https://mainnet.infura.io/v3/eb09ae0eec584eb19d33f7a17d20ff34");
    const saleContractInstance = new ethers.Contract(NFTConfig.astrobotSaleNFTContractAddress, AstrobotSaleNFT.abi, provider);

    // get the current phase
    saleContractInstance.getActivePhase().then(activePhase => {
      console.log('activePhase: ', activePhase);
      setPhase(activePhase);
      return activePhase
    }).then(async activePhase => {
      // get Maximum Token user can mint in this phase
      let numberOfTokenUserCanMint = ethers.BigNumber.from(await saleContractInstance.maxPhase1MintLimit()).toNumber();
      if (activePhase === 2) {
        numberOfTokenUserCanMint = ethers.BigNumber.from(await saleContractInstance.maxPhase2MintLimit()).toNumber();
      }
      console.log('numberOfTokenUserCanMint: ', numberOfTokenUserCanMint);
      setNumberOfTokenCanBeMinted(numberOfTokenUserCanMint);

      getPriceForPhase(activePhase, saleContractInstance);
    })

    // get max supply available
    saleContractInstance.maxSupply().then(maxSupply => {
      let maxSupplyNFT = ethers.BigNumber.from(maxSupply).toNumber();
      maxSupplyNFT = maxSupplyNFT + 250;
      setTotalNFTTokens(maxSupplyNFT);
      return maxSupplyNFT;
    }).then(maxSupply => {
      return saleContractInstance.totalMinted().then((totalMintedNFT) => {
        // check total NFT Minted so far
        let totalMintedNFTs = ethers.BigNumber.from(totalMintedNFT).toNumber();
        console.log('totalMintedNFTs: ', totalMintedNFTs);
        totalMintedNFTs = totalMintedNFTs + 25;
        setTotalMinted(totalMintedNFTs);
        setTotalRemaining(maxSupply - totalMintedNFTs);
        setPercentage(( (totalMintedNFTs / maxSupply) * 100 ));
      });
    });
  }, []);

  const connectWallet = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      setConnectingWallet(true);
      // get the account from metamask
      const [account] = await window.ethereum.request({method: 'eth_requestAccounts'});
      let accountCaps = utils.getAddress(account);
      accountCaps = accountCaps.toLowerCase(); // TODO - Lower case
      console.log('accountCaps: ', accountCaps);
      setAddress(accountCaps);

      // get balance in ETH
      const balance = await provider.getBalance(accountCaps);
      setBalance(ethers.utils.formatEther(balance));
      console.log('balance: ', ethers.utils.formatEther(balance));

      // get the signer and init the contracts
      const signer = provider.getSigner();
      const saleContractInstance = new ethers.Contract(NFTConfig.astrobotSaleNFTContractAddress, AstrobotSaleNFT.abi, signer);
      setSaleContract(saleContractInstance);
      const mainContractInstance = new ethers.Contract(NFTConfig.astrobotNFTContractAddress, AstrobotNFT.abi, signer);
      setMainContract(mainContractInstance);

      // get the current phase
      const activePhase = await saleContractInstance.getActivePhase();
      console.log('activePhase: ', activePhase);
      setPhase(activePhase);

      // get max supply available
      let maxSupply = ethers.BigNumber.from(await saleContractInstance.maxSupply()).toNumber();
      console.log('maxSupply: ', maxSupply);
      maxSupply = maxSupply + 250;
      setTotalNFTTokens(maxSupply);

      // check total NFT Minted so far
      let totalMintedNFTs = ethers.BigNumber.from(await saleContractInstance.totalMinted()).toNumber();
      console.log('totalMintedNFTs: ', totalMintedNFTs);
      totalMintedNFTs = totalMintedNFTs + 25;
      setTotalMinted(totalMintedNFTs);
      setTotalRemaining(maxSupply - totalMintedNFTs);
      setPercentage(( (totalMintedNFTs / maxSupply) * 100 ));


      // get Maximum Token user can mint in this phase
      let numberOfTokenUserCanMint = ethers.BigNumber.from(await saleContractInstance.maxPhase1MintLimit()).toNumber();
      if (activePhase === 2) {
        numberOfTokenUserCanMint = ethers.BigNumber.from(await saleContractInstance.maxPhase2MintLimit()).toNumber();
      } else if (activePhase === 3) {
        numberOfTokenUserCanMint = maxSupply - totalMintedNFTs;
      }
      console.log('numberOfTokenUserCanMint: ', numberOfTokenUserCanMint);
      setNumberOfTokenCanBeMinted(numberOfTokenUserCanMint);

      // get number of token user already minted
      let numberOfTokenUserAlreadyMinted = ethers.BigNumber.from(await saleContractInstance.userPhase1Minted(accountCaps)).toNumber();
      if (activePhase === 2) {
        numberOfTokenUserAlreadyMinted = ethers.BigNumber.from(await saleContractInstance.userPhase2Minted(accountCaps)).toNumber();
      }
      console.log('numberOfTokenUserAlreadyMinted: ', numberOfTokenUserAlreadyMinted);
      setNumAlreadyMinted(numberOfTokenUserAlreadyMinted);

      // check total minted in all phases
      const totalMintedNFTsInAllPhases = ethers.BigNumber.from(await saleContractInstance.userMinted(accountCaps)).toNumber();
      console.log('totalMintedNFTsInAllPhases: ', totalMintedNFTsInAllPhases);
      setNumAlreadyMintedInAllPhases(totalMintedNFTsInAllPhases);

      // get price for current phase
      await getPriceForPhase(activePhase, saleContractInstance);


      if (activePhase === 1 && NFTConfig.whiteListAddress.includes(accountCaps)) {
        setAddressOnWhiteList(true);
      }

      if (activePhase === 2 && NFTConfig.waitListAddress.includes(accountCaps)) {
        setAddressOnWaitList(true);
      }

      if (activePhase === 3) {
        // If public phase then we don't care if address in on list or not
        setAddressOnWhiteList(true);
        setAddressOnWaitList(true);
      }

      let mintStatus = true;
      if (!accountCaps) {
        mintStatus = false;
      }

      if (activePhase !== 3 && !NFTConfig.whiteListAddress.includes(accountCaps) && !NFTConfig.waitListAddress.includes(accountCaps)) {
        mintStatus = false;
      }

      if (num < 1) {
        mintStatus = false;
      } else if (num > numberOfTokenUserCanMint) {
        mintStatus = false;
      }
      setMintEnabled(mintStatus);
    } catch (e) {
      console.error('e: ', e);
    } finally {
      setConnectingWallet(false);
    }
  }

  async function mint() {
    console.log('checking mint possible');
    let mintStatus = true;
    if (!address) {
      mintStatus = false;
      errorNotification('Invalid Wallet Address', 'Please connect your wallet');
      return true;
    }

    if (phase !== 3 && !addressOnWhiteList && !addressOnWaitList) {
      mintStatus = false;
      errorNotification('You are not in our list', 'Your address in not in our whitelist/waitlist. Please wait for public sale');
      return true;
    }

    if (num < 1) {
      mintStatus = false;
      errorNotification('Invalid Mint Number', 'Please choose valid number of NFT(s) to mint');
      return true;
    }

    if (num > numberOfTokenCanBeMinted) {
      mintStatus = false;
      errorNotification('Invalid Mint Number', 'You are not allowed to mint this many NFT(s)');
      return true;
    }

    if (num > totalRemaining) {
      mintStatus = false;
      errorNotification('Invalid Mint Number', 'You are not allowed to mint this many NFT(s)');
      return true;
    }

    // check wallet has enough balance
    const requiredBalance = roundOffDecimal((price * num), 4);
    if (!(requiredBalance <= balance)) {
      mintStatus = false;
      errorNotification('Not enough balance', `Your account has an insufficient balance. You have ${balance} ETH in your wallet but require ${requiredBalance} ETH.`);
    }

    if (phase !== 3 && numAlreadyMinted + num > numberOfTokenCanBeMinted) {
      mintStatus = false;
      if (numberOfTokenCanBeMinted - numAlreadyMinted <= 0) {
        if (phase === 1) {
          errorNotification('Mint Limit Exceeded', `You have already minted ${numAlreadyMinted} NFTs, you can't mint any more during the whitelist sale. Wait until 4 pm EST to mint again during the waitlist sale.`);
        } else {
          errorNotification('Mint Limit Exceeded', `You have already minted ${numAlreadyMinted} NFTs, you can't mint any more during the waitlist sale. Wait until 8 pm EST to mint again during the public sale.`);
        }
      } else {
        errorNotification('Invalid Number', `You have already minted ${numAlreadyMinted} NFT(s), you can only mint ${numberOfTokenCanBeMinted - numAlreadyMinted} more NFT(s)`);
      }
    }

    if (mintStatus) {
      console.log('mint start');
      setMinting(true);
      try {
        if (phase === 1) {
          await whiteListMint();
        } else if (phase === 2) {
          await waitListMint();
        } else if (phase === 3) {
          await publicMint();
        }
      } catch (e) {
        console.log('mint error: ', e);
      } finally {
        setMinting(false);
      }
    }
  }

  async function whiteListMint() {
    try {
      const leaves = NFTConfig.whiteListAddress.map(addr => keccak256(addr));
      const merkleProof = await generateMerkleProof(leaves);
      console.log('merkleProof: ', merkleProof);

      const priceCal = roundOffDecimal((num * price), 4);
      console.log('priceCal: ', priceCal);
      const startMint = await saleContract.mintWhitelisted(num, merkleProof, {
        value: ethers.utils.parseEther(`${priceCal}`)
      });
      console.log('startMint: ', startMint);

      await startMint.wait();
      await mintFinished();
    } catch (e) {
      parseError(e);
    }
  }

  async function waitListMint() {
    try {
      const leaves = NFTConfig.waitListAddress.map(addr => keccak256(addr));
      const merkleProof = await generateMerkleProof(leaves);
      console.log('merkleProof: ', merkleProof);

      const priceCal = roundOffDecimal((num * price), 4);
      console.log('priceCal: ', priceCal);
      const startMint = await saleContract.mintWaitlist(num, merkleProof, {
        value: ethers.utils.parseEther(`${priceCal}`)
      });
      console.log('startMint: ', startMint);

      await startMint.wait();
      await mintFinished();
    } catch (e) {
      parseError(e);
    }
  }

  async function publicMint() {
    try {
      console.log('price: ', price, num, `${(num * price)}`);
      console.log('balance: ', balance);

      const priceCal = roundOffDecimal((num * price), 4);
      console.log('priceCal: ', priceCal);
      const startMint = await saleContract.mintPublicSale(num, {
        value: ethers.utils.parseEther(`${priceCal}`)
      });

      console.log('startMint: ', startMint);

      await startMint.wait();
      await mintFinished();
    } catch (e) {
      parseError(e);
    }
  }

  async function parseError(e) {
    try {
      const error = e.toString();
      console.error('e4: ', e);
      if (error.includes('invalid ether amount')) {
        errorNotification('Not enough balance', 'You does not have enough balance for this transaction');
      } else if (error.includes('user rejected transaction')) {
        errorNotification('User Rejected Transaction', 'You rejected the transaction, Please try again.');
      } else if (error.includes('Sale completed')) {
        errorNotification('Sale Completed', 'The Sale in current phase is completed.');
      } else {
        errorNotification('Mint Not completed', 'Something went wrong while minting, please contact tokenmetrics.com');
        errorNotification('Error', error);
      }
    } catch (err) {
      console.log('e: ', err);
    }
  }

  async function mintFinished() {
    // get number of token user minted
    let numberOfTokenUserMinted = ethers.BigNumber.from(await saleContract.userPhase1Minted(address)).toNumber();
    if (phase === 2) {
      numberOfTokenUserMinted = ethers.BigNumber.from(await saleContract.userPhase2Minted(address)).toNumber();
    }
    console.log('numberOfTokenUserMinted: ', numberOfTokenUserMinted);
    setNumAlreadyMinted(numberOfTokenUserMinted);

    // check total minted in all phases
    const totalMintedNFTsInAllPhases = ethers.BigNumber.from(await saleContract.userMinted(address)).toNumber();
    console.log('totalMintedNFTsInAllPhases: ', totalMintedNFTsInAllPhases);
    setNumAlreadyMintedInAllPhases(totalMintedNFTsInAllPhases);

    // check total NFT Minted so far
    let totalMintedNFTs = ethers.BigNumber.from(await saleContract.totalMinted()).toNumber();
    console.log('totalMintedNFTs: ', totalMintedNFTs);
    totalMintedNFTs = totalMintedNFTs + 25;
    setTotalMinted(totalMintedNFTs);
    setTotalRemaining(totalNFTTokens - totalMintedNFTs);
    setPercentage(( (totalMintedNFTs / totalNFTTokens) * 100 ));

    successModal(`You have successfully minted ${num} Astrobot Society NFTs. Tokens in your wallet ${totalMintedNFTsInAllPhases}.`);
  }

  async function generateMerkleProof(leaves) {
    const merkleTree = new MerkleTree(leaves, keccak256, {sort: true});
    MerkleTree.print(merkleTree);
    const leaf = keccak256(address);
    // const rootHash = merkleTree.getHexRoot();
    // console.log('rootHash: ', rootHash);
    return merkleTree.getHexProof(leaf);
  }

  function errorNotification(title, msg) {
    notification.error({
      message: title,
      description: msg
    });
  }

  function successModal(content) {
    Modal.success({
      content,
    });
  }

  async function getPriceForPhase(currentPhase, saleContractInstance) {
    let price = 0;

    if (currentPhase === 1) {
      price = ethers.utils.formatEther(await saleContractInstance.phaseOnePrice());
    } else if (currentPhase === 2) {
      price = ethers.utils.formatEther(await saleContractInstance.phaseTwoPrice());
    } else if (currentPhase === 3) {
      price = ethers.utils.formatEther(await saleContractInstance.phaseThreePrice());
    }

    price = parseFloat(price);
    console.log('price: ', price);
    setPrice(price);
  }

  const incNum = () => {
      setNum(num + 1);
  };

  const decNum = () => {
    if (num <= 1) {
      return;
    }
    setNum(num - 1);
  };

  const roundOffDecimal = (number, fractionDigits = 2) => {
    if (number === null || number === undefined || isNaN(number)) {
      return number;
    }
    if (number < 0) {
      number = number * -1;
    }
    return parseFloat(number.toFixed(fractionDigits));
  }

  function numberWithCommas(number, fractionDigits = 2) {
    if (number === null || number === undefined || isNaN(number)) {
      return number;
    }
    if (number < 1000) {
      return roundOffDecimal(number, fractionDigits);
    }
    return `${roundOffDecimal(number, 2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const content = (
      <div style={{ background: "#1D1335", padding: "10px" }}>
        <p> •&nbsp; Limit 3 per wallet in Whitelist sale</p>
        <p> •&nbsp; Limit 10 per wallet in Waitlist sale</p>
        <p> •&nbsp; No Limit per wallet in Public sale</p>
      </div>
  );

  const whiteListText = () => {
    if (address) {
      if (NFTConfig.whiteListAddress.includes(address)) {
        return <MintH2><h6 style={{ color: 'white', fontSize: '26px' }}>You are on the whitelist!</h6></MintH2>;
      } else if (NFTConfig.waitListAddress.includes(address)) {
        return <MintH2><h6 style={{ color: 'white', fontSize: '26px' }}>You are on waitlist!</h6></MintH2>;
      } else if ((!NFTConfig.whiteListAddress.includes(address) && !NFTConfig.waitListAddress.includes(address))) {
        return <MintH2><h6 style={{ color: 'white', fontSize: '26px' }}>Please wait until public sale!</h6></MintH2>;
      }
    }
    return null;
  }

  return (
    <>
      <Helmet>
        <title>4K Membership NFT Collection by Token Metrics</title>
        <meta name="title" content="Astrobot Society"/>
        <meta
    name="description"
    content="4K Membership NFT Collection by Token Metrics"
    />
      </Helmet>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Header toggle={toggle} />
      <FollowingScroll />

      <MintContainer id="mint">
        <MintBg>
          <VideoBg src={Video} type="video/mp4" autoPlay loop muted/>
        </MintBg>

        <>
        {/* Main Page */}

          {/* phase = 0 - mint has not yet started   */}
          {phase === 0 ? <MintMainHeroContent>
            <MintH2>Mint Over</MintH2>
            <MintBtnWrapper>
              <Anchor href="https://opensea.io/collection/astrobot-society-by-token-metrics" target="_blank">Buy on OpenSea</Anchor>

            </MintBtnWrapper>
            {whiteListText()}
          </MintMainHeroContent> : null }

          {/*  Phase 1 - Mint for whitelist members only */}
          {phase > 0 && phase < 4 ?  <MintHeroContent>
            <MintHeader>
              <MintH1>Astrobot Society {""}
                {phase === 1 ? "Whitelist": ""}
                {phase === 2 ? "Waitlist": ""}
                {phase === 3 ? "Public": ""}
                {""} Sale</MintH1>
              {phase === 1 ? <MintP>Early members of our community can mint up to {numberOfTokenCanBeMinted} NFTs for {price} ETH a piece.</MintP> : null}
              {phase === 2 ? <MintP>Those who joined the Astrobot Society waitlist can mint {numberOfTokenCanBeMinted} NFTs for {price} ETH each.</MintP> : null}
              {phase === 3 ? <MintP>Mint unlimited Astrobot Society NFTs for {price} ETH a piece</MintP> : null}
            </MintHeader>{" "}
            <MintBottom>
              <MintContent>
                <MintBtnWrapper2>
                  {connectingWallet ? <Button to="" primary="true" dark="true"><Spin spinning={connectingWallet} style={{ marginLeft: '10px', marginTop: '10px' }} /></Button> : null}
                  {!connectingWallet && !address ? <Button to="signup" primary="true" dark="true" onClick={connectWallet}>Connect Your Wallet</Button> : null}
                  {!connectingWallet && address && (addressOnWhiteList || addressOnWaitList) ? <><SelectButton>
                    <option value="">{address}</option>
                  </SelectButton>
                      {numAlreadyMinted > 0 && phase !== 3 ? <MintP3>Already minted {numAlreadyMinted}/{numberOfTokenCanBeMinted} NFT(s)</MintP3> : null}
                      {numAlreadyMinted > 0 && phase === 3 ? <MintP3>Already minted {numAlreadyMinted} NFT(s)</MintP3> : null}
                  </>: null}
                  {!connectingWallet && address && !addressOnWhiteList && !addressOnWaitList ? <Button to="" primary="true" dark="true" style={{ background: "#FFCF30", color: "#FF0000" }}>THIS ADDRESS IS NOT ON {""}
                    {phase === 1 ? "WHITELIST" : ""}
                    {phase === 2 ? "WAITLIST" : ""}
                    {phase === 3 ? "LIST" : ""}
                  </Button> : null}
                </MintBtnWrapper2>
                <Wrapper>
                  <Minus onClick={decNum}>-</Minus>
                  <Number>{num}</Number>
                  <Plus onClick={incNum}>+</Plus>
                </Wrapper>
                <MintBtnWrapper1>
                  {minting ? <Button to="" primary="true" dark="true"><Spin spinning={minting} style={{ marginLeft: '10px', marginTop: '10px' }} /></Button> : null}
                  {!minting ? <Button to="signup" primary="true" dark="true" disabled={!mintEnabled} onClick={mint}>mint</Button> : null }
                </MintBtnWrapper1>
                <MintP3>
                  {" "}
                  {num} for {""}
                  {roundOffDecimal((num * price), 4)}
                  {""} ETH
                  <Popover placement="right" content={content} trigger="click">
                    <img style={{ marginLeft: "0.5rem", textAlign: "center" }} src={img} alt="Astrobot Society"/>
                  </Popover>
                </MintP3>

                <Progress
                    percent={percentage}
                    showInfo={false}
                    style={{ width: "70%" }}
                />
                <MintP2> {numberWithCommas(totalMinted, 0)}/{numberWithCommas(totalNFTTokens, 0)}</MintP2>
                <MintP1> Minted </MintP1>
              </MintContent>
              <MintContent1>
                <Imageastro>
                  <img
                    src={astroGif}
                    style={{
                      border: "3px solid #93d3ff",
                      height: "284px",
                      width: "284px",
                      background: "#6128FF",
                    }}
                    alt="Astrobot Society"/>
                </Imageastro>{" "}
                <MintP4>
                  Each Astrobot gives holders lifetime access to the powerful AI
                  of Token Metrics + other amazing utility!
                </MintP4>
              </MintContent1>
            </MintBottom>
          </MintHeroContent> : null }

          {/*  Phase 4 - All NFTs Sold out */}
          {phase === 4 ?   <MintHeroContent>
            {/*<MintContentSoldOut>*/}
              <MintBtnWrapper2>
                <Anchor href="https://opensea.io/collection/astrobot-society-by-token-metrics" target="_blank">
                  Buy on OpenSea
                </Anchor>
              </MintBtnWrapper2>
              <Imageastro>
                <img
                    src={astroGif}
                    style={{
                      height: "167px",
                      width: "167.59px",
                      position: "relative",
                      top: "-2rem",
                    }}
                    alt="Astrobot Society"
                />
              </Imageastro>{" "}
              <Progress
                  percent={100}
                  showInfo={false}
                  style={{ width: "283px", position: "relative", top: "-5.5rem" }}
                  strokeColor="#93D3FF"
              />
              <MintP2>SOLD OUT</MintP2>
              <Anchor href="https://opensea.io/collection/astrobot-society-by-token-metrics" target="_blank">
                Buy on OpenSea
              </Anchor>
            {/*</MintContentSoldOut>*/}
          </MintHeroContent> : null }

        </>

      </MintContainer>
      <Footer />
    </>
  );
};

export default Mint;
