import React from "react";
import "./FAQ.css";
import upArrow from "../pics/Vector 6.svg";
import downArrow from "../pics/Vector 7.svg";
import {
  Image,
  DropDown,
  Container,
  Wrap,
  FaqSection,
  FaqContainer,
  FaqBg,
  VideoBg,
  FaqContent,
  FaqH1,
  FaqH2,
} from "./FaqElement";
import Video from "../videos/nebula 1.mp4";
import { useState, useRef } from "react";

const Data = [
  {
    id: 1,
    question: "What is an NFT ?",
    answer:
      "An NFT, or non-fungible token, is a one-of-a-kind digital item that gives users the ability to prove ownership of goods.",
    open: false,
  },
  {
    id: 2,
    question: "What is Ethereum?",
    answer:
      "Ethereum is a blockchain and smart contract platform that allows developers to build applications, launch NFTs and other tokens. Astrobot Society will be launched on Ethereum.",
    open: false,
  },
  {
    id: 3,
    question: "What is a Wallet?",
    answer:
      "Crypto wallets store your private keys and allow you to send, receive, and spend your Ethereum. It’s needed to purchase and mint a Astrobot Society NFT. Having a wallet gives you an Ethereum address, which is also where your NFT is stored. ",
    open: false,
  },
  {
    id: 4,
    question: "What is Token Metrics?",
    answer:
      "Token Metrics is an industry-leading AI data & analytics crypto research and media platform. Our work has appeared in CNN, Forbes, and Business Insider.  Check out this video of our Founder & CEO, Ian Balina, appearing on CNBC.",
    open: false,
  },
  {
    id: 5,
    question: "When is the mint?",
    answer:
      "Soon. Follow us on Twitter and check this Discord for the mint date announcement.",
    open: false,
  },
  {
    id: 6,
    question: "Is there a whitelist?",
    answer:
      "Yes, we are rewarding our early community with Whitelist spots. We are guaranteeing those on the Whitelist with an opportunity to mint an Astrobot Society NFT. You may sign up for the Premint raffle",
    open: false,
  },
  {
    id: 7,
    question: "Is there a limit to the number of mints per wallet?",
    answer: "Yes, this will be announced closer to mint.",
    open: false,
  },
  {
    id: 8,
    question: "Will Astrobots be revealed right away?",
    answer: "Astrobots will be revealed shortly after the sale ends.",
    open: false,
  },
  {
    id: 9,
    question: "How do I view my Astrobot?",
    answer:
      "You can view your Astrobot on your OpenSea profile or in your Ethereum wallet.",
    open: false,
  },
  {
    id: 10,
    question: "Who owns the art?",
    answer:
      "Astrobot holders will own the IP and underlying artwork of their NFTs. ",
    open: false,
  },
  {
    id: 11,
    question: "What is the price?",
    answer:
      "More details on the sale will be announced closer to the mint date.",
    open: false,
  },
  {
    id: 12,
    question: "Can I get a whitelist spot for my community?",
    answer: "Reach out to the team on Discord for potential collaborations.",
    open: false,
  },
  {
    id: 13,
    question:
      "What features of the Token Metrics platform will I have lifetime access to?",
    answer:
      "The Astrobots will be split into four categories based on Token Metrics’s current plans: Basic, Advanced, Premium, & VIP. Learn more about the different plans",
    open: false,
  },
  {
    id: 14,
    question: "What is the Community Treasure Chest?",
    answer:
      "10% of primary sales & 2.5% of royalties on secondary go back into the Community Treasure Chest. Over a period of months after sell-out, holders can enter giveaway raffles!",
    open: false,
  },
  {
    id: 15,
    question: "How will the giveaways for holders work?",
    answer:
      "The structure of the giveaways for holders will be announced closer to mint date.",
    open: false,
  },
  {
    id: 16,
    question: "How will royalties work?",
    answer: ["2.5% to Community Treasure Chest and 5% to Token Metrics"],
    open: false,
  },
  {
    id: 17,
    question: "Is Astrobot Society related to Token Metrics?",
    answer:
      "Yes, the same team who brought you Token Metrics is releasing Astrobot Society. Astrobot Society is an official Token Metrics NFT collection.",
    open: false,
  }
];

const FAQ = () => {
  const [clicked, setClicked] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [name, setName] = useState(null);

  const toggle = (index) => {
    if (clicked === index) {
      setIsActive(false);
      return setClicked(null);
    }
    setIsActive(true);

    switch (index) {
      case 0:
        setName("zero");
        break;
      case 1:
        setName("one");
        break;
      case 2:
        setName("two");
        break;
      case 3:
        setName("three");
        break;
      case 4:
        setName("four");
        break;
      case 5:
        setName("five");
        break;
      case 6:
        setName("six");
        break;
      case 7:
        setName("seven");
        break;
      case 8:
        setName("eight");
        break;
      case 9:
        setName("nine");
        break;
      case 10:
        setName("ten");
        break;
      case 11:
        setName("eleven");
        break;
      case 12:
        setName("twelve");
        break;
      case 13:
        setName("thirteen");
        break;
      case 14:
        setName("fourteen");
        break;
      case 15:
        setName("fifteen");
        break;
      case 16:
        setName("sixteen");
        break;
      case 17:
        setName("seventeen");
        break;
      case 18:
        setName("eighteen");
        break;
      default:
        setName(null);
        break;
    }
    setClicked(index);
  };
  return (
    <>
      <FaqContainer id="faq" className={isActive ? name : "normal"}>
        <FaqBg>
          <video
            style={{
              width: "100%",
              height: "100%",
              OObjectFit: "cover",
              objectFit: "cover",
            }}
            playsInline
            autoPlay
            loop
            muted
            src={Video}
            type="video/mp4"
          ></video>
        </FaqBg>
        <FaqContent>
          <FaqH2>FAQ</FaqH2>
          <FaqH1>LOOKING FOR ANSWERS?</FaqH1>
          <FaqSection>
            <Container>
              {Data.map((item, index) => {
                return (
                  <>
                    <Wrap onClick={() => toggle(index)} key={index}>
                      <h1>{item.question}</h1>
                      <Image
                        src={clicked === index ? upArrow : downArrow}
                      ></Image>
                    </Wrap>
                    {clicked === index ? (
                      <DropDown>
                        {item.question == "Is there a whitelist?" ? (
                          <p>
                            {item.answer}{" "}
                            <a
                              style={{
                                color: "white",
                                borderBottom: "1px solid white",
                              }}
                              target="_blank"
                              href="https://www.premint.xyz/astrobotsociety/"
                            >
                              here
                            </a>
                          </p>
                        ) : item.question == "What is Token Metrics?" ? (
                          <p>
                            Token Metrics is an industry-leading AI data &
                            analytics crypto research and media platform. Our
                            work has appeared in CNN, Forbes, and Business
                            Insider. Check out this{" "}
                            <a
                              style={{
                                color: "white",
                                borderBottom: "1px solid white",
                              }}
                              target="_blank"
                              href="https://www.cnbc.com/video/2022/05/31/ethereums-merge-will-change-the-entire-climate-in-crypto-assets-says-token-metrics-balina.html"
                            >
                              video
                            </a>{" "}
                            of our Founder & CEO, Ian Balina, appearing on CNBC.
                          </p>
                        ) : (
                          <p>
                            {item.answer}{" "}
                            {item.question ==
                            "What features of the Token Metrics platform will I have lifetime access to?" ? (
                              <a
                                style={{
                                  color: "white",
                                  borderBottom: "1px solid white",
                                }}
                                target="_blank"
                                href="https://tokenmetrics.com/pricing.html?&&hsLang=en"
                              >
                                here
                              </a>
                            ) : (
                              ""
                            )}
                          </p>
                        )}
                      </DropDown>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
            </Container>
          </FaqSection>
        </FaqContent>
      </FaqContainer>

      {/* <IconContext.Provider value={{color:'#00FFB9'}}> */}

      {/* </IconContext.Provider> */}
    </>
  );
};

export default FAQ;
