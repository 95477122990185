import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";

export const HeroContainer = styled.div`
  /* background: #0c0c0c; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 800px;
  position: relative;
  z-index: 1;

  :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: radial-gradient(
      circle,
      rgba(61, 12, 223, 0.5242471988795518) 50%,
      rgba(32, 14, 235, 0) 98%
    );
    z-index: 2;
  }
`;
export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  /* background: #232a34; */
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const HeroH1 = styled.h1`
  font-family: "Chakra Petch";
  font-style: normal;
  text-align: Center;
  font-weight: 700;
  font-size: 95px;
  line-height: 95px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #93d3ff;

  @media screen and (max-width: 950px) {
    font-size: 80px;
  }

  @media screen and (max-width: 768px) {
    font-size: 55px;
  }

  @media screen and (max-width: 570px) {
    line-height: 55px;
    margin-bottom: 0;
  }
`;

export const HeroP = styled.p`
  margin-top: 2rem;
  max-width: 900px;
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 169%;
  align-items: center;
  text-align: center;

  color: #ffffff;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    width: 80%;

    font-weight: 400;
  }

  @media screen and (max-width: 440px) {
    width: 100%;
  }
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-top: 40px;
  width: 125px;

  @media screen and (min-width: 769px) {
    display: none;
  }
`;
export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
`;

export const SocialIcon = styled.img`
  height: 35px;
  width: 35px;
  &:hover {
    height: 38px;
    width: 35px;
  }
  /* margin-bottom:10px; */
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`;

export const Anchor = styled.a`
  box-sizing: border-box;
  width: 271px;
  height: 51px;
  background: #93d3ff;
  border: 3px solid #93d3ff;
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: black;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
`;
