import styled from "styled-components";

export const FaqContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  min-height: 1900px;
  position: relative;
  z-index: 1;

  :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: radial-gradient(
      circle,
      rgba(61, 12, 223, 0.5242471988795518) 50%,
      rgba(32, 14, 235, 0) 98%
    );
    z-index: 2;
  }
`;

export const FaqBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;

  :-webkit-media-controls {
    display: none !important;
  }
`;

export const FaqContent = styled.div`
  z-index: 3;
  /* max-width:1200px; */
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FaqH1 = styled.h1`
  color: #fff;
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;
export const FaqH2 = styled.h1`
  color: #fff;
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #93d3ff;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }

  @media screen and (max-width: 480px) {
    font-size: 24px;
    line-height: 3rem;
  }
`;

export const FaqP = styled.p`
  margin-top: 1rem;
  color: #fff;
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const FaqSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  margin-top: 2rem;
`;

export const Container = styled.div`
  /* position:absolute ; */
  width: 800px;
  box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3);

  @media screen and (max-width: 850px) {
    width: 90%;
  }

  @media screen and (max-width: 415px) {
    width: 95%;
    height: auto;
  }
`;
export const Wrap = styled.div`
  background: transparent;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  border: 2px solid rgba(147, 211, 255, 1);
  text-align: center;
  font-family: "Chakra Petch";
  cursor: pointer;
  margin-bottom: 0rem;

  h1 {
    padding: 1rem;
    /* font-size:1rem; */
    font-family: "Chakra Petch";
    font-style: normal;
    font-weight: 700;
    text-align: left;
    font-size: 20px;
    color: #fff;
    margin-bottom: 0rem;

    @media screen and (max-width: 440px) {
      padding: 10px;
    }

    @media screen and (max-width: 490px) {
      font-size: 18px;
    }
  }
`;
export const Image = styled.img`
  margin-right: 1rem;
`;

export const DropDown = styled.div`
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin-bottom: 0rem;
  border: 1px solid rgba(147, 211, 255, 1);
  height: auto;

  p {
    padding: 15px;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    color: #fff;
    max-width: 900px;
    margin-bottom: 0rem;

    @media screen and (max-width: 620px) {
      padding: 7px 15px;
    }

    @media screen and (max-width: 440px) {
      font-size: 16px;
    }
  }
`;
