import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import { useState } from "react";
import Mint from "./components/Mint/mint";
import ScrollToTop from "./components/scroll-to-top/scrolltotop";
import Origins1 from "./components/origins/origins1";
import Helmet from "react-helmet";

const App = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Helmet>
        <title>Astrobot Society</title>
        <meta name="title" content="Astrobot Society"></meta>
        <meta
          name="description"
          content="Astrobot Society is the best NFT project to buy in 2022, giving you access to AI-based crypto tools at Token Metrics."
        ></meta>
      </Helmet>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} exact />
            {/*<Route path="/mint" element={<Mint />} exact />*/}
            <Route path="/origins" element={<Origins1 />} exact />
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
};

export default App;
