import React from "react";
import Pic1 from "../pics/Group 95.svg";
import Discort from "../pics/discort.svg";
import twitter from "../pics/twitter.svg";
import opensea from "../pics/opensea-seeklogo.com 1.svg";
import footerLogo from "../pics/logo mono.svg";
import {
  LogoFooter,
  Anchortag,
  SocialIconsFooter,
  SocialIcon1,
  Footerdata,
  HorizontalLine,
  FooterContent,
  FooterP,
  SocialIcon,
  ServicesIcon,
  FooterContainer,
  FooterWrap,
  SocialIconLink,
  SocialIcons,
  SocialLogo,
  SocialMediaWrap,
  SocialMedia,
  WebsiteRights,
} from "./FooterElements";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/">
              <ServicesIcon src={Pic1}></ServicesIcon>
            </SocialLogo>
            <SocialIcons>
              <SocialIconLink
                href="https://twitter.com/AstrobotSociety"
                target="_blank"
                aria-label="twitter"
              >
                {" "}
                <SocialIcon src={twitter}></SocialIcon>
              </SocialIconLink>
              <SocialIconLink
                href="https://discord.com/invite/tokenmetricsofficial"
                target="_blank"
                aria-label="Facebook"
              >
                {" "}
                <SocialIcon src={Discort}></SocialIcon>
              </SocialIconLink>
            </SocialIcons>
            <WebsiteRights>
              Don't Miss Out, Join Now For Early Access
            </WebsiteRights>
          </SocialMediaWrap>
        </SocialMedia>
        <HorizontalLine></HorizontalLine>
        <FooterContent>
          <FooterP>DISCLAMER</FooterP>
          <FooterP>
            Token Metrics Media LLC is a regular publication of information,
            analysis, and commentary focused especially on blockchain technology
            and business, cryptocurrency, blockchain-based tokens, market
            trends, and trading strategies.
          </FooterP>

          <FooterP>
            Token Metrics Media LLC does not provide individually tailored
            investment advice and does not take a subscriber’s or anyone’s
            personal circumstances into consideration when discussing
            investments; nor is Token Metrics Advisers LLC registered as an
            investment adviser or broker-dealer in any jurisdiction.
          </FooterP>

          <FooterP>
            Information contained herein is not an offer or solicitation to buy,
            hold, or sell any security. The Token Metrics team has advised and
            invested in many blockchain companies. A complete list of their
            advisory roles and current holdings can be viewed here:{" "}
            <Anchortag
              href="https://tokenmetrics.com/disclosures/"
              target="_blank"
            >
              https://tokenmetrics.com/disclosures/
            </Anchortag>
          </FooterP>

          <FooterP>
            Token Metrics Media LLC relies on information from various sources
            believed to be reliable, including clients and third parties, but
            cannot guarantee the accuracy and completeness of that information.
            Additionally, Token Metrics Media LLC does not provide tax advice,
            and investors are encouraged to consult with their personal tax
            advisors.
          </FooterP>

          <FooterP>
            All investing involves risk, including the possible loss of money
            you invest, and past performance does not guarantee future
            performance. Ratings and price predictions are provided for
            informational and illustrative purposes, and may not reflect actual
            future performance.
          </FooterP>

          <FooterP>
            A smart contract will hold all funds from the NFT sale, and the
            owner can withdraw them at any point
          </FooterP>
        </FooterContent>
        <HorizontalLine></HorizontalLine>
        <Footerdata>
          <LogoFooter>
            <SocialIconsFooter>
              <SocialIcon1 src={footerLogo}></SocialIcon1>
            </SocialIconsFooter>
            <FooterP>ASTROBOT SOCIETY 2022- ALL rights reserved</FooterP>
          </LogoFooter>
        </Footerdata>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
